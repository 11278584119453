.image-slider {
    .splide {
        &__slide {
            &:not(.is-current) {
                figure {
                    picture {
                        img {
                            opacity: .4;
                        }
                    }

                    footer {
                        opacity: .4;
                        pointer-events: none;

                        span {
                            &.icon {
                                &::before {
                                    pointer-events: none;
                                }
                            }
                        }
                    }
                }
            }

            figure {
                picture {
                    img {
                        transition: opacity .2s linear;
                    }
                }
            }
        }

        &__arrow {
            &--prev {
                img {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}
